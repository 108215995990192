@import url("https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext");
::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient(#e9ebee, #bbb); }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910; }

::selection {
  background: #8a451f;
  color: #e9ebee; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 1050px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 850px) {
    html {
      font-size: 45%; } }

body {
  font-family: "Varela Round", sans-serif;
  color: #331910;
  background: linear-gradient(to bottom, #e9ebee, #bbb); }

.container {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto; }
  @media only screen and (max-width: 800px) {
    .container {
      width: 90%; } }

.navigation {
  display: none;
  /* .cover {
        width: 20vw;
        height: 100vh;
        background-color: rgba(black, .8);
        position: fixed;
        top: 0;
        left: 0;
    } */ }
  @media only screen and (max-width: 700px) {
    .navigation {
      display: block; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    height: 6rem;
    width: 6rem;
    position: fixed;
    top: .8rem;
    right: 2rem;
    border-radius: 50%;
    z-index: 2000;
    text-align: center;
    cursor: pointer; }
  .navigation__nav {
    visibility: hidden;
    background-image: url(../../../../img/grain.png);
    background-color: #bbb;
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1500;
    width: 75vw;
    height: 100vh;
    transform: translateX(80vw);
    transition: all .5s;
    overflow: auto; }
  .navigation__list {
    width: 100%;
    list-style: none;
    text-align: left;
    font-size: 2rem; }
  .navigation__item {
    padding: 3rem; }
    .navigation__item:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .navigation__checkbox:checked ~ .navigation__nav {
    visibility: visible;
    opacity: 1;
    transform: translateX(0); }
  .navigation__icon {
    position: relative;
    margin-top: 3rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 3rem;
      height: 2px;
      background-color: #331910;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s; }
    .navigation__icon::before {
      top: -.9rem; }
    .navigation__icon::after {
      top: .8rem; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg); }
