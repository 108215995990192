.Kontakt {
  padding: 6rem 0 2.5rem;
  color: #eee;
  background: url(../../img/grain.png) center center rgba(51,25,16,.1);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: #331910;
  

  a {
      color: inherit;
      text-decoration: none;
  }

  &__container {
      min-height: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media only screen and (max-width: 800px) {
          flex-direction: column;
      }

      &__box {
        
          @media only screen and (max-width: 800px) {
              &:not(:first-child){
                  margin-top: 4rem;
              }
            }
          
            p {
              opacity: 0.8;
            }
              

          h1 {
              font-size: 2rem;
              color: #331910;
              margin-bottom: 2rem;
              opacity: 0.8;
          }

          &:last-child {
              margin-top: 2rem;
              width: 100%;
          }
  
          &__text {
              display: flex;
              flex-direction: column;
      
              a {
                  opacity: 0.8;
                  transition: all .25s;
                  margin-bottom: .8rem;
          
      
                  @media only screen and (max-width: 450px) {
                      &:not(:last-child) {
                          margin-bottom: 2rem;
                      }
                  }
      
                  &:hover,
                  &:focus {
                      color: rgb(158, 93, 28);
                  }
              }
          }

          .ft {
            opacity: .2;
            margin: 2.5rem auto;
            border: 1px solid #331910;
          }
      }
  }



}