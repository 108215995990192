@import '../../../App.scss';

.main-menu {
    z-index: 10;    
  
    @media only screen and (max-width: 700px) {
      display: none;
    }
  
    &__menu-list {
      display: flex;
      margin-bottom: -1rem;
    }
  
    &__menu-item {
      display: flex;
      position: relative;
      font-size: 1.5rem;
      font-weight: 600;
      z-index: 10;
      transition: all .3s;
  
      & > a {
        color: $color-primary-dark;
        text-decoration: none;
        padding: 1rem 4rem;
        transition: all .2s;
      }
  
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: $color-primary-light;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
  
      &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
  
      & .produkty {
        background-color: $background-color;
        background-image: url(../../../img/grain.png);
        position: absolute;
        left: 0;
        top: 3.74rem;
        visibility: hidden;
        opacity: 0;
        flex-direction: column;
        min-width: 131%;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, .6);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
  
        li {
          list-style: none;
          padding: 1rem 2rem;
  
          a {
            text-decoration: none;
            color: $color-primary-dark;
  
            &:hover,
            &:active {
              color: $color-primary-light;
              transition: all .2s;
            }
          }
        }
  
      }
  
      & .sluzby {
        background-color: $background-color;
        background-image: url(../../../img/grain.png);
        position: absolute;
        left: 0;
        top: 3.74rem;
        visibility: hidden;
        opacity: 0;
        flex-direction: column;
        min-width: 170%;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, .6);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
  
        li {
          list-style: none;
          padding: 1rem 2rem;
  
          a {
            text-decoration: none;
            color: $color-primary-dark;
  
            &:hover,
            &:active {
              color: $color-primary-light;
              transition: all .2s;
            }
          }
        }
      }
    }
  }
  
  
  #sluzby,
  #produkty {
    &:hover .sluzby,
    &:hover .produkty {
      visibility: visible;
      opacity: 1;
      transition: all .2s;
    }
  }