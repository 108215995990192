//COLOR VARIABLES
$background-color: #e9ebee;
$background-color-dark: #bbb;
$color-primary: #8a451f;
$color-primary-light: #BB6E1F;
$color-primary-dark: #331910;
$color-black: rgba(0, 0, 0, .5);

//FONT VARIABLES
@import url('https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext');
$font-primary: 'Varela Round', sans-serif;

::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient($background-color, $background-color-dark);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910;
}

::selection {
  background: $color-primary;
  color: $background-color;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

$bp-large: 62.5em; //1000px
$bp-medium: 42.5em; //680px
$bp-small: 31.25em; //500px

html {
  box-sizing: border-box;
  font-size: 62.5%; //10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: 1050px) {
    font-size: 55%;
  }

  @media only screen and (max-width: 850px) {
    font-size: 45%;
  }
}

body {
  font-family: $font-primary;
  color: $color-primary-dark;
  background: linear-gradient(to bottom, $background-color, $background-color-dark);
}

.container {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto;
  //background-image: url(../img/grain.png);

  @media only screen and (max-width: 800px) {
    width: 90%;
  }
}