@import url(https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&subset=latin-ext);
::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient(#e9ebee, #bbb); }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910; }

::-moz-selection {
  background: #8a451f;
  color: #e9ebee; }

::selection {
  background: #8a451f;
  color: #e9ebee; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 1050px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 850px) {
    html {
      font-size: 45%; } }

body {
  font-family: "Varela Round", sans-serif;
  color: #331910;
  background: linear-gradient(to bottom, #e9ebee, #bbb); }

.container {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto; }
  @media only screen and (max-width: 800px) {
    .container {
      width: 90%; } }

::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient(#e9ebee, #bbb); }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910; }

::-moz-selection {
  background: #8a451f;
  color: #e9ebee; }

::selection {
  background: #8a451f;
  color: #e9ebee; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 1050px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 850px) {
    html {
      font-size: 45%; } }

body {
  font-family: "Varela Round", sans-serif;
  color: #331910;
  background: linear-gradient(to bottom, #e9ebee, #bbb); }

.Header_container__2HRNH {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto; }
  @media only screen and (max-width: 800px) {
    .Header_container__2HRNH {
      width: 90%; } }

.Header_Header__ger5z {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #331910;
  margin: 1.5rem 0 4rem 0;
  height: 10vh; }
  .Header_Header__logo__3gcgU {
    font-size: 4rem;
    text-transform: uppercase;
    justify-self: flex-start; }
    .Header_Header__logo__3gcgU:hover, .Header_Header__logo__3gcgU:focus {
      color: #BB6E1F; }
      .Header_Header__logo__3gcgU:hover span, .Header_Header__logo__3gcgU:focus span {
        color: #331910; }
    .Header_Header__logo__3gcgU span {
      font-size: 3.5rem;
      color: #BB6E1F;
      text-transform: lowercase;
      margin-left: .3rem;
      transition: color .25s; }
    @media only screen and (max-width: 42.5em) {
      .Header_Header__logo__3gcgU {
        font-size: 4rem; } }
  .Header_Header__contact__2Lqo6 {
    -webkit-transform: translateY(0.6rem);
            transform: translateY(0.6rem); }
    @media only screen and (max-width: 700px) {
      .Header_Header__contact__2Lqo6 {
        display: none; } }
    .Header_Header__contact__2Lqo6 i {
      opacity: 0.75;
      padding: 0.5rem;
      transition: all .2s; }
      .Header_Header__contact__2Lqo6 i:hover {
        opacity: .9; }
      .Header_Header__contact__2Lqo6 i.Header_fa2x__Phbgo {
        font-size: 2.3rem; }
      @media only screen and (max-width: 42.5em) {
        .Header_Header__contact__2Lqo6 i {
          top: .2rem; } }
    @media only screen and (max-width: 31.25em) {
      .Header_Header__contact__2Lqo6 {
        display: none; } }
  .Header_Header__ger5z a {
    text-decoration: none;
    color: inherit;
    transition: all .25s;
    position: relative;
    /* Show the tooltip text when you mouse over the tooltip container */ }
    .Header_Header__ger5z a .Header_tooltip__3cm-K {
      /* Span text */
      display: block;
      font-size: 1.4rem;
      font-weight: 600;
      visibility: hidden;
      color: #BB6E1F;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      width: 17rem;
      opacity: 0;
      transition: all .25s;
      margin-left: -2.5rem; }
    .Header_Header__ger5z a:hover span {
      transition: all .25s;
      opacity: 1;
      visibility: visible; }
  .Header_Header__ger5z .Header_menu-collapse__2oXdK {
    display: none;
    justify-self: flex-end; }
    @media only screen and (max-width: 31.25em) {
      .Header_Header__ger5z .Header_menu-collapse__2oXdK {
        display: block; } }
    .Header_Header__ger5z .Header_menu-collapse__btn__3aMPk {
      border: none;
      border-radius: 0;
      background-color: #BB6E1F;
      height: 2px;
      width: 3.5rem; }
      .Header_Header__ger5z .Header_menu-collapse__btn__3aMPk::before, .Header_Header__ger5z .Header_menu-collapse__btn__3aMPk::after {
        background-color: #331910;
        height: 2px;
        width: 3.5rem;
        content: '';
        display: block; }
      .Header_Header__ger5z .Header_menu-collapse__btn__3aMPk::before {
        -webkit-transform: translateY(-1.1rem);
                transform: translateY(-1.1rem); }
      .Header_Header__ger5z .Header_menu-collapse__btn__3aMPk::after {
        -webkit-transform: translateY(0.9rem);
                transform: translateY(0.9rem); }

::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient(#e9ebee, #bbb); }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910; }

::-moz-selection {
  background: #8a451f;
  color: #e9ebee; }

::selection {
  background: #8a451f;
  color: #e9ebee; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 1050px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 850px) {
    html {
      font-size: 45%; } }

body {
  font-family: "Varela Round", sans-serif;
  color: #331910;
  background: linear-gradient(to bottom, #e9ebee, #bbb); }

.container {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto; }
  @media only screen and (max-width: 800px) {
    .container {
      width: 90%; } }

.main-menu {
  z-index: 10; }
  @media only screen and (max-width: 700px) {
    .main-menu {
      display: none; } }
  .main-menu__menu-list {
    display: flex;
    margin-bottom: -1rem; }
  .main-menu__menu-item {
    display: flex;
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    z-index: 10;
    transition: all .3s; }
    .main-menu__menu-item > a {
      color: #331910;
      text-decoration: none;
      padding: 1rem 4rem;
      transition: all .2s; }
    .main-menu__menu-item:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: #BB6E1F;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s; }
    .main-menu__menu-item:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1); }
    .main-menu__menu-item .produkty {
      background-color: #e9ebee;
      background-image: url(/static/media/grain.4f22805a.png);
      position: absolute;
      left: 0;
      top: 3.74rem;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      min-width: 131%;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.6);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px; }
      .main-menu__menu-item .produkty li {
        list-style: none;
        padding: 1rem 2rem; }
        .main-menu__menu-item .produkty li a {
          text-decoration: none;
          color: #331910; }
          .main-menu__menu-item .produkty li a:hover, .main-menu__menu-item .produkty li a:active {
            color: #BB6E1F;
            transition: all .2s; }
    .main-menu__menu-item .sluzby {
      background-color: #e9ebee;
      background-image: url(/static/media/grain.4f22805a.png);
      position: absolute;
      left: 0;
      top: 3.74rem;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      min-width: 170%;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.6);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px; }
      .main-menu__menu-item .sluzby li {
        list-style: none;
        padding: 1rem 2rem; }
        .main-menu__menu-item .sluzby li a {
          text-decoration: none;
          color: #331910; }
          .main-menu__menu-item .sluzby li a:hover, .main-menu__menu-item .sluzby li a:active {
            color: #BB6E1F;
            transition: all .2s; }

#sluzby:hover .sluzby,
#sluzby:hover .produkty,
#produkty:hover .sluzby,
#produkty:hover .produkty {
  visibility: visible;
  opacity: 1;
  transition: all .2s; }

::-webkit-scrollbar {
  width: 1.4rem;
  background: linear-gradient(#e9ebee, #bbb); }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  height: .6rem;
  border: .4rem solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: .7rem;
  background-color: #331910; }

::-moz-selection {
  background: #8a451f;
  color: #e9ebee; }

::selection {
  background: #8a451f;
  color: #e9ebee; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 1050px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 850px) {
    html {
      font-size: 45%; } }

body {
  font-family: "Varela Round", sans-serif;
  color: #331910;
  background: linear-gradient(to bottom, #e9ebee, #bbb); }

.container {
  min-height: 90vh;
  width: 85vw;
  margin: 0 auto; }
  @media only screen and (max-width: 800px) {
    .container {
      width: 90%; } }

.navigation {
  display: none;
  /* .cover {
        width: 20vw;
        height: 100vh;
        background-color: rgba(black, .8);
        position: fixed;
        top: 0;
        left: 0;
    } */ }
  @media only screen and (max-width: 700px) {
    .navigation {
      display: block; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    height: 6rem;
    width: 6rem;
    position: fixed;
    top: .8rem;
    right: 2rem;
    border-radius: 50%;
    z-index: 2000;
    text-align: center;
    cursor: pointer; }
  .navigation__nav {
    visibility: hidden;
    background-image: url(/static/media/grain.4f22805a.png);
    background-color: #bbb;
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1500;
    width: 75vw;
    height: 100vh;
    -webkit-transform: translateX(80vw);
            transform: translateX(80vw);
    transition: all .5s;
    overflow: auto; }
  .navigation__list {
    width: 100%;
    list-style: none;
    text-align: left;
    font-size: 2rem; }
  .navigation__item {
    padding: 3rem; }
    .navigation__item:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .navigation__checkbox:checked ~ .navigation__nav {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .navigation__icon {
    position: relative;
    margin-top: 3rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 3rem;
      height: 2px;
      background-color: #331910;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s; }
    .navigation__icon::before {
      top: -.9rem; }
    .navigation__icon::after {
      top: .8rem; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }

.Spinner_Spinner__3gZRk {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 64px;
  height: 100vh; }
  .Spinner_Spinner__3gZRk div {
    position: absolute;
    top: 40vh;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: black;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0); }
    .Spinner_Spinner__3gZRk div:nth-child(1) {
      left: 6px;
      -webkit-animation: Spinner_lds-ellipsis1__3DxkQ 0.6s infinite;
              animation: Spinner_lds-ellipsis1__3DxkQ 0.6s infinite; }
    .Spinner_Spinner__3gZRk div:nth-child(2) {
      left: 6px;
      -webkit-animation: Spinner_lds-ellipsis2__2Ynu5 0.6s infinite;
              animation: Spinner_lds-ellipsis2__2Ynu5 0.6s infinite; }
    .Spinner_Spinner__3gZRk div:nth-child(3) {
      left: 26px;
      -webkit-animation: Spinner_lds-ellipsis2__2Ynu5 0.6s infinite;
              animation: Spinner_lds-ellipsis2__2Ynu5 0.6s infinite; }
    .Spinner_Spinner__3gZRk div:nth-child(4) {
      left: 45px;
      -webkit-animation: Spinner_lds-ellipsis3__27ZM9 0.6s infinite;
              animation: Spinner_lds-ellipsis3__27ZM9 0.6s infinite; }

@-webkit-keyframes Spinner_lds-ellipsis1__3DxkQ {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Spinner_lds-ellipsis1__3DxkQ {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Spinner_lds-ellipsis2__2Ynu5 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes Spinner_lds-ellipsis2__2Ynu5 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@-webkit-keyframes Spinner_lds-ellipsis3__27ZM9 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes Spinner_lds-ellipsis3__27ZM9 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

.Footer_Kontakt__1_09N {
  padding: 6rem 0 2.5rem;
  color: #eee;
  background: url(/static/media/grain.4f22805a.png) center center rgba(51, 25, 16, 0.1);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: #331910; }
  .Footer_Kontakt__1_09N a {
    color: inherit;
    text-decoration: none; }
  .Footer_Kontakt__container__2RT_y {
    min-height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media only screen and (max-width: 800px) {
      .Footer_Kontakt__container__2RT_y {
        flex-direction: column; } }
    @media only screen and (max-width: 800px) {
      .Footer_Kontakt__container__box__20Cfa:not(:first-child) {
        margin-top: 4rem; } }
    .Footer_Kontakt__container__box__20Cfa p {
      opacity: 0.8; }
    .Footer_Kontakt__container__box__20Cfa h1 {
      font-size: 2rem;
      color: #331910;
      margin-bottom: 2rem;
      opacity: 0.8; }
    .Footer_Kontakt__container__box__20Cfa:last-child {
      margin-top: 2rem;
      width: 100%; }
    .Footer_Kontakt__container__box__text__3dHuw {
      display: flex;
      flex-direction: column; }
      .Footer_Kontakt__container__box__text__3dHuw a {
        opacity: 0.8;
        transition: all .25s;
        margin-bottom: .8rem; }
        @media only screen and (max-width: 450px) {
          .Footer_Kontakt__container__box__text__3dHuw a:not(:last-child) {
            margin-bottom: 2rem; } }
        .Footer_Kontakt__container__box__text__3dHuw a:hover, .Footer_Kontakt__container__box__text__3dHuw a:focus {
          color: #9e5d1c; }
    .Footer_Kontakt__container__box__20Cfa .Footer_ft__1e0pF {
      opacity: .2;
      margin: 2.5rem auto;
      border: 1px solid #331910; }

