@import '../../../../App.scss';

.navigation {
    display: none;

    /* .cover {
        width: 20vw;
        height: 100vh;
        background-color: rgba(black, .8);
        position: fixed;
        top: 0;
        left: 0;
    } */
  
    @media only screen and (max-width: 700px) {
        display: block;
      }
  
    &__checkbox {
      display: none;
    }
  
    &__button {
      //background-color: $color-primary-dark;
      height: 6rem;
      width: 6rem;
      position: fixed;
      top: .8rem;
      right: 2rem;
      border-radius: 50%;
      z-index: 2000;
      text-align: center;
      cursor: pointer;
    }
  
    &__nav {
        visibility: hidden;
        //text-align: center;
        background-image: url(../../../../img/grain.png);
        background-color: $background-color-dark;
        color: #fff;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1500;
        width: 75vw;
        height: 100vh;
        transform: translateX(80vw);
        transition: all .5s;
        overflow: auto;
    }
  
    &__list {
        width: 100%;
        //display: inline-block;
        list-style: none;
        text-align: left;
        font-size: 2rem;
    }
  
    &__item {
        padding: 3rem;

        &:not(:last-child){
            border-bottom: 1px solid rgba(#fff, .3);
        }
    }
  
    //FUNCTIONALITY
    &__checkbox:checked ~ &__nav {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  
  
    //ICON
    &__icon {
      position: relative;
      margin-top: 3rem;
  
      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: $color-primary-dark;
        display: inline-block;
      }
  
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
      }
  
      &::before { top: -.9rem; }
      &::after { top: .8rem; }
    }
  
    &__checkbox:checked + &__button &__icon {
      background-color: transparent;
    }
  
    &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
    }
  
    &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }