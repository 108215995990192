@import '../../App.scss';

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-primary-dark;
    margin: 1.5rem 0 4rem 0;
    height: 10vh;
  
    &__logo {
      font-size: 4rem;
      text-transform: uppercase;
      justify-self: flex-start;

      &:hover,
      &:focus {
        color: $color-primary-light;

        span {
          color: $color-primary-dark;
        }
      }

      span {
        font-size: 3.5rem;
        color: $color-primary-light;
        text-transform: lowercase;
        margin-left: .3rem;
        transition: color .25s;
      }
  
      @media only screen and (max-width: $bp-medium) {
        font-size: 4rem;
      }
    }
  
    &__contact {
      transform: translateY(.6rem);
      
      @media only screen and (max-width: 700px) {
        display: none;
      }
      
      & i {
        opacity: 0.75;
        padding: 0.5rem;
        transition: all .2s;

        &:hover {
          opacity: .9;
        }

        &.fa2x {
          font-size: 2.3rem;
        }
  
        @media only screen and (max-width: $bp-medium) {
          top: .2rem;
        }
      }
  
      @media only screen and (max-width: $bp-small){
        display: none;
      }
    }
  
    a {
      text-decoration: none;
      color: inherit;
      transition: all .25s;
      position: relative;

      .tooltip {
        /* Span text */
        display: block;
        font-size: 1.4rem;
        font-weight: 600;
          visibility: hidden;
          //background-color: rgba(#331910, .9);
          color: #BB6E1F;
          text-align: center;
          padding: 5px 0;
          border-radius: 6px;
          position: absolute;
          z-index: 1;
          width: 17rem;
          opacity: 0;
          transition: all .25s;
          margin-left: -2.5rem;
        }
        
        /* Show the tooltip text when you mouse over the tooltip container */
        &:hover span {
          transition: all .25s;
          opacity: 1;
          visibility: visible;
        }
    }
  
    .menu-collapse {
      display: none;
      justify-self: flex-end;
  
      @media only screen and (max-width: $bp-small){
        display: block;
      }
  
      &__btn {
        border: none;
        border-radius: 0;
        background-color: $color-primary-light;
        height: 2px;
        width: 3.5rem;
  
        &::before,
        &::after {
          background-color: $color-primary-dark;
          height: 2px;
          width: 3.5rem;
          content: '';
          display: block;
        }
  
        &::before {transform: translateY(-1.1rem);}
        &::after {transform: translateY(.9rem);}
      }
    }
  }
  